
@import '@app-styles';

$wrapper-padding: 0.5rem;
$stl-width: 295px;
$reserved-bb-spacing: 322px;

.pl-lightbox {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  cursor: none;

  &-wrapper {
    align-self: center;
    max-height: 100vh;
    position: relative;
  }

  &-container {
    width: auto;
    min-width: 336px;
    height: auto;
    background-color: transparent;
    border-radius: 2px;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 $reserved-bb-spacing 0 0;

    // WIDTHS:
    // not shoppable: margins both sides: 1295px
    // not shoppable: margins 1 side:      973px
    // shoppable: margins both sides      1630px
    // shoppable: margins 1 side:         1263px

    // not shoppable
    @media screen and (min-width: 1295px) {
      &:not(.shoppable) {
        margin: 0 $reserved-bb-spacing;
      }
    }

    &.shoppable {
      @media screen and (min-width: 1630px) {
        margin: 0 $reserved-bb-spacing;
      }
    }

    &-nav-left,
    &-nav-right {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      cursor: pointer;

      .lightbox-nav {
        display: none;
      }

      &:hover {
        .lightbox-nav {
          display: flex;
        }
      }
    }
    &-nav-left {
      left: 0;
      width: 25%;
      height: 100%;
    }
    &-nav-right {
      right: 0;
      width: 75%;
      height: 100%;
    }
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }

  &-enter, &-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .o-PhotoGalleryPromo__m-ProductsList {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    align-self: center;
    min-width: $stl-width;
    cursor: default !important;
  }

  .button-close {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 1000;
  }

  &-ad {
    min-width: 300px;
    align-self: center;

    position: absolute;
    top: 50%;
    right: $wrapper-padding;
    transform: translate(0, -50%);
  }

  &.small-screen {
    overflow-y: auto;
    padding-top: 82px;

    .pl-lightbox-wrapper {
      max-height: none;
      min-height: 800px;
      height: auto;
      padding-top: 82px;
    }

    .pl-lightbox-ad {
      top: 82px;
      transform: none;
    }
  }
}
