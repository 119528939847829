
@import '@app-styles';

.button-close {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  width: 56px;
  height: 56px;

  &:hover {
    background-color: $white;
    cursor: pointer;
  }
}
