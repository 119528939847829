
@import '@app-styles';

$scale: 0.75;

.lightbox-nav {
  display: flex;
  z-index: 10;
  width: 56px;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.65);
  cursor: none;
  pointer-events: none;
  animation: show-nav-arrow 125ms linear 1;
  transform: scale($scale) rotate(0);
  transition: transform 125ms linear;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: -28px 0 0 -28px;

  @include border-radius(50%);

  @supports (backdrop-filter: blur()) {
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.45);
  }

  > svg {
    fill: #000;
  }

  &.disabled {
    > svg {
      opacity: 0.25;
    }
  }

  &-next {
    transform: scale($scale) rotate(0turn);
  }

  &-prev {
    transform: scale($scale) rotate(-0.5turn);

    > svg {
      margin-left: 4px;
    }
  }
}
