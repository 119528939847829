
@import '@app-styles';

.lightbox-image {
  background-color: transparent;
  width: auto;
  max-width: 54vw;
  align-self: center;

  &__container {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    position: relative;

    &-inner {
      position: relative;
    }
  }

  &__info {
    margin-top: space(2);
  }

  &__img {
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    width: auto;
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 1;
    height: space(4);
  }

  &__credit {
    @include gothamSsm();
    font-size: 12px;
    line-height: 15px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
