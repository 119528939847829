@charset "utf-8";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/helpers.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/content.sass";
@import "~bulma/sass/form/_all";
@import "~bulma/sass/elements/icon.sass";
@import "~bulma/sass/elements/image.sass";
@import "~bulma/sass/elements/title.sass";
@import "~bulma/sass/components/card.sass";
@import "~bulma/sass/components/media.sass";
@import "~bulma/sass/components/navbar.sass";
// @import "~bulma/bulma";

.container {
  margin: 0;
  position: relative;
}

.footer {
  padding: 0 0 6rem;
}

.container-site, .pv-container-site {
  padding: 0 !important;
  overflow: unset;
}

.container-site > .row {
  margin-right: unset;
}

.bs-docs-container > .row {
  margin-right: unset;
}

.photoLibraryViewerPage, .photoLibraryLandingPage {
  overflow: unset !important;
}

html::-webkit-scrollbar {
  display: none;
}

a {
  color: #0fadc4;
}

h1 {
  margin-top: 40px;
  text-align: center;
}

.loading {
  text-align: center;
  font-size: 20px;
}

.display {
  justify-content: center;
  align-content: center;
  width: 100vw;
}

#app {
  @extend .display;
  background: #F7F7F7 !important;
}

[v-cloak] {
  visibility: hidden;
}

.card-image--1 {
  background-color: #46535E;
}

.card-image--2 {
  background-color: #9DC6CC;
}

.card-image--3 {
  background-color: #F0C4CC;
}

.card-image--4 {
  background-color: #DFCBDE;
}

.card-image--5 {
  background-color: #DFCBDE;
}

.card-image--6 {
  background-color: #D9D5D2;
}

.tags-shop {
  position: absolute;
  bottom: 15px;
  left: 8px;
  border-radius: 15px;
  background-color: rgba(59, 65, 67, 0.52);
  -webkit-backdrop-filter: blur(1px);
}

.icon--shop {
  height: .6rem;
  width: .6rem;
  margin-right: .25rem;
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 13'%3E%3Cpath d='M11.5.6h-5c-.1 0-.2 0-.3.1l-2 2-.7.7L.2 6.7c-.2.2-.2.4 0 .6l5 5c.1.1.4.1.5 0L9 8.9l.8-.7 2-2c.1-.1.1-.2.1-.3v-5c-.1-.2-.2-.3-.4-.3zM9.3 3.9c-.5 0-.8-.4-.8-.8s.4-.8.8-.8.8.4.8.8-.3.8-.8.8z' fill='%23202429'/%3E%3C/svg%3E");
}

.icon--search {
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg class='icon-search' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14' height='15'%3E%3Cpath stroke='%23C2C3C4' stroke-width='2' d='M9.85 10.674l-.264-.264.616-.704a5.25 5.25 0 1 0-1.301 1.077l.667-.391.282.282zm-3.6.826a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 0 1 0 10.5z'/%3E%3Cpath d='M10.955 10.364l2.517 2.517a1 1 0 0 1-1.414 1.414l-2.65-2.65a6.25 6.25 0 1 1 1.548-1.28zM6.25 10.5a4.25 4.25 0 1 0 0-8.5 4.25 4.25 0 0 0 0 8.5z'/%3E%3C/svg%3E");
}

.icon--inlineSearch {
  z-index: 1;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
}

input:focus+.icon--inlineSearch {
  display: none;
}

.icon--remove {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
}

.icon-backArrow {
  margin-left: -1.5rem;
}

.icon--back {
  background-size: cover;
  background-image: url("data:image/svg+xml,%3Csvg class='icon-backArrow' xmlns='http://www.w3.org/2000/svg' width='9' height='15'%3E%3Cpath fill='%23202429' d='M3.299 7.375l5.631-5.82L7.375 0 0 7.375l7.375 7.376 1.555-1.555-4.721-4.721z'/%3E%3C/svg%3E");
}

.card-content {
  display: none;
  margin-right: 10px;
}

.media-content {
  margin-bottom: 1rem;
}

.content-tags {
  margin-bottom: .5rem !important;
}

.is-flat {
  background: whitesmoke;
  color: black;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 500;
  border: none;
  font-size: 12px;
  padding: 1rem 1.75rem;
  letter-spacing: .0625em;

  &:hover {
    // TODO: remove need for !important here
    background: whitesmoke !important;
    color: black !important;
  }
}

.is-search {
  padding: 1rem 1.1rem 1rem .5rem;
  background-color: transparent;
  border-right: 1px #f5f5f5 solid;

  .active-search & {
    padding-right: 0;
  }

  &:hover {
    background-color: transparent !important;
    border-right: 1px #f5f5f5 solid !important;
  }
}

.is-inline {
  width: 30%;
}

.is-outlined {
  border-width: 2px;
}

.is-shoppable {
  .card-content {
    padding: 0;
    display: inherit;
  }

  .media {
    display: none;
  }

  .content-tags {
    display: none;
  }
}

.sticky {
  position: fixed;
  top: 10px;
}

.derp {
  height: 52px;
  width: 100vw;
}

.o-HeaderFresh {
  z-index: 32; // temporary bump </3 ads, remove when addressed by ads team
  box-shadow: none;
}

.o-HeaderFresh__a-Icon--close {
  z-index: 33; // temporary bump </3 ads, remove when addressed by ads team
}

.ad-text {
  font-size: .8rem;
  color: #46535E;
  text-align: center;
}

.feedSearch {
  display: flex;
  flex-direction: row;
  justify-content: left;
  background-color: #fff;
  width: 95.6vw;
}

.active-search {
  width: 97vw;
  justify-content: space-around;

  a:last-child {
    margin-left: auto;
  }

  .is-search {
    letter-spacing: inherit;
    text-transform: none;
    border: none;

    &:hover {
      border: none !important;
      cursor: unset;
    }
  }

  .icon--search {
    margin-right: .5rem !important;
  }

  .navbar-item:not(.is-search) {
    margin-left: 0;
    margin-right: 0 !important;
  }
}

input::placeholder {
  text-align: center;
}

input:focus::placeholder {
  color: transparent;
}

.input {
  box-shadow: none !important;
  border-radius: 0 !important;

  .has-search & {
    border: none;
    margin-left: -3.5rem;
    width: 85vw;
  }
}

.input--search, .input--active-search {
  position: absolute;
  flex-grow: 2;
  border: none;
  width: 96.5vw;
}

.input--search {
  bottom: 10px;
  margin-left: .5rem;
}

.input--active-search {
  left: -1.5rem;
  bottom: 0;
  top: .5px;
}

.navbar {
  -webkit-transform: translateZ(0);
  min-height: 0;
  z-index: 9;
}

.navbar.has-shadow {
  box-shadow: none;
}

.navbar .button:last-child {
  margin-right: .5rem;
}

.navbar-home {
  position: absolute;
  background-color: transparent;
}

.navbar-brand.has-shadow {
  align-items: center;
  border-bottom: 1px solid whitesmoke;

  .icon {
    z-index: 10;
  }
}

.navbar-brand.has-shadow+.navbar-brand.selected-facets {
  padding-top: 0;
}

.selected-facets > .navbar-item:hover {
  color: inherit !important;
}

.navbar-brand.selected-facets {
  min-height: unset;
  padding-left: 0;
  margin: .5rem 0;
  padding: .5rem 0;

  .navbar-item {
    padding: 0;
    height: 19px;
    margin-left: 1rem;
    font-weight: 700;
    font-size: 12px;

    &.clear-all, &.search-query {
      padding-bottom: 3px;
      margin: 3px .75rem 0;
      border: none;
      border-bottom: 3px solid whitesmoke;
    }

    &.rooms {
      border-color: #C2EAF2;
    }

    &.style {
      border-color: #FAD7F9;
    }

    &.color {
      border-color: #DBE6B3;
    }

    &.shop {
      border-color: #FFD7DF;
    }

    .fa-times {
      font-size: 10px;
      margin-left: .25rem;
      margin-top: .0625rem;
    }
  }
}

.navbar-home+.navbar-brand {
  padding-left: 2.75rem;
}

.button.is-danger.is-outlined {
  border-color: #03afc4;
  color: #03afc4;

  &:hover {
    border-color: #03afc4;
    background-color: #fff;
    color: #03afc4;
  }
}

.button.is-success.is-outlined {
  border-color: #d964d5;
  color: #d964d5;

  &:hover {
    background-color: #fff;
    border-color: #d964d5;
    color: #d964d5;
  }
}

.button.is-color.is-outlined {
  background: #fff;
  color: rgb(0,0,0);
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
  border: solid 2px transparent;

  &:hover {
    background-color: #fff;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
  }
}

.button.is-dark:hover {
  background-color: transparent;
  border-color: #363636;
  color: #363636;
}

.button[disabled] {
  background-color: transparent !important;
  border-color: #dbdbdb !important;
  box-shadow: none;
  color: #dbdbdb !important;

  &:before {
    background: unset;
  }
}

.button.back {
  margin-bottom: 0;
  margin-left: -14px;
  margin-top: -8px;
  z-index: 1;
  height: 52px;
  width: 40px;
  border: 1px solid whitesmoke;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  position: sticky;
  left: 0;

  .icon {
    margin-left: -12px;
  }
}

@media (max-width: 647px) {
  .navbar-brand {
    padding-left: .5rem;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    .buttons {
      flex: 0 0 auto;
    }

    &.has-background-light {
      margin-left: 0;

      .buttons {
        margin-left: .5rem;

        .is-text {
          margin-right: 0;
          padding: .5rem;
        }
      }
    }
  }

  .navbar-brand::-webkit-scrollbar {
    display: none;
  }

  .card {
    flex-basis: 40%;
  }

  .card--full {
    flex-basis: 97%;
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all .075s linear;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transition: all .1s linear;
  transform: translateY(-52px);
  opacity: 0;
}

.is-accent--rooms {
  background: #C2EAF2;

  &:hover {
    background: #C2EAF2 !important;
  }
}

.is-accent--style {
  background: #FAD7F9;

  &:hover {
    background: #FAD7F9 !important;
  }
}

.is-accent--color {
  background: #DBE6B3;

  &:hover {
    background: #DBE6B3 !important;
  }
}

.is-accent--shop {
  background: #FFD7DF;

  &:hover {
    background: #FFD7DF !important;
  }
}

.white:before {
  background: #F2F2F2;
}

.neutral:before {
  background: linear-gradient(-45deg, #EBE6D9 0%, #D5BFAA 100%);
}

.gray:before {
  background: linear-gradient(-45deg, #C7C7C7 0%, #F0F0F0 100%);
}

.blue:before {
  background: linear-gradient(-45deg, #74D1EE 0%, #0E66AA 100%);
}

.brown:before {
  background: linear-gradient(-45deg, #D67349 0%, #5A2412 100%);
}

.black {
  &:before {
    background: #000000;
  }
}

.green:before {
  background: linear-gradient(-45deg, #BCE157 0%, #207934 100%);
}

.metallic:before {
  background: linear-gradient(-45deg, #98791A 0%, #F1CD58 100%);
}

.black_and_white:before {
  background: linear-gradient(-45deg, #444040 0%, #DFDFDF 100%);
}

.red:before {
  background: linear-gradient(-45deg, #F45941 0%, #AD0C15 100%);
}

.multicolor:before {
  background: linear-gradient(-45deg, #EE1F1F 0%, #F37215 24%, #FDB100 46%, #61B440 62%, #0085CF 80%, #672693 100%);
}

.yellow:before {
  background: linear-gradient(-45deg, #FAF067 0%, #D1930A 100%);
}

.pink:before {
  background: linear-gradient(-45deg, #FF93B5 0%, #BC1C3F 100%);
}

.orange:before {
  background: linear-gradient(-45deg, #FFC235 0%, #D8480E 100%);
}

.purple:before {
  background: linear-gradient(-45deg, #D283E1 0%, #592577 100%);
}

.m-ProductsList__m-Partner {
  margin: 10.5px 0 14px;
  font-size: 11px;
  color: #202429;
  font-weight: 700;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  clear: right;
}

.m-ProductsList__a-LegalText {
  margin-top: 10.5px;
  font-size: 11px;
  color: #939597;
}

.m-ProductsList__m-Body {
  width: 100vw;

  .l-Columns {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 0 10.5px 0 0;
  }
}

.m-ProductsList__m-ProductSmall {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 37vw;
  margin-right: 10.5px;
  text-align: left;
  white-space: normal;

  a {
    display: block;
    color: #202429;
  }
}

.m-ProductSmall__m-MediaWrap {
  height: 0;
  position: relative;
  margin-bottom: 7px;
  padding-bottom: 100%;
  overflow: hidden;
}

.m-ProductSmall__m-ImageWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }

  &:before {
    position: absolute;
    z-index: 1;
    content: " ";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .085);
  }
}

.m-ProductSmall__m-TextWrap {
  padding-right: 5px;
}

.m-ProductSmall__a-Headline {
  font-size: 11px;
  margin-bottom: 0;
}

.m-ProductSmall__a-HeadlineText {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 36vw;
  font-weight: 300;
}

.m-ProductSmall__a-Price {
  font-size: 12px;
  font-weight: 700;
}

.m-ProductSmall__a-ShippingInfo {
  font-size: 10px;
  color: #939597;
}

.show-img-index {
  &:after {
    content: attr(data-img-index);
    font: 32px verdana;
    color: #333;
    background: rgba(255, 255, 255, .85);
    border-radius: 8px 8px 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}