
.dfp-ad__label {
  text-align: center;
  width: 100%;
  display: block;
}

.disable-ad-refresh {
  visibility: hidden;
}
