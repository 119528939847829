
@import '@style/variables/index.scss';
.c-Feed {
  &--stl {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: $feed-gutter-half;
    background-color: #fff;

    .l-Reverse & {
      flex-direction: row-reverse;
    }

    .l-Split & {
      flex-direction: column-reverse;
    }

    .c-Feed--item {
      flex: 1;
      margin: 0;
      .photoLibraryLandingPage &,
      .photoLibraryViewerPage & {
        height: 635px;
      }
    }
  }
}
