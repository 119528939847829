
.navbar-brand {
  &.selected-facets {
    border-top: 1px solid #efefef;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 !important;
    padding: 0.75rem 0 !important;
    position: relative;
    z-index: 1;

    .search-query {
      margin: 0 12px;
    }
  }
}
