// Base colors
$black: #000;
$red: #ff0000;
$white: #fff;

// HGTV Base Shades
$gray-50: #F2F2F2;
$gray-100: #E3E3E3;
$gray-200: #D4D4D4;
$gray-300: #C2C3C4;
$gray-400: #B8B9BA;
$gray-500: #939597;
$gray-600: #575C5F;
$gray-700: #3B4143;
$gray-800: #2E3335;
$gray-900: #202429;
$green-50: #FCFFF2;
$green-100: #DAE6B3;
$green-200: #A2C728;
$green-300: #90B516;
$green-400: #82A314;
$green-500: #718F11;
$green-600: #617A0F;
$green-700: #51660C;
$green-800: #41520A;
$green-900: #34400B;
$red-50: #FFF2F5;
$red-100: #FED3DC;
$red-200: #FF9DB0;
$red-300: #FE718C;
$red-400: #F7375C;
$red-500: #EC1B43;
$red-600: #C91637;
$red-700: #A1122C;
$red-800: #800E23;
$red-900: #590D1B;
$teal-50: #EBFCFF;
$teal-100: #BBF2FA;
$teal-200: #81DDEB;
$teal-300: #64D6E8;
$teal-400: #36C1D6;
$teal-500: #0FADC4;
$teal-600: #0E9DB3;
$teal-700: #0D8DA1;
$teal-800: #0A7080;
$teal-900: #0D4F59;

// HGTV Brand Colors
$green: #A3C728;
$green-light: #DBE6B3;
$green-muted: #C7D0A5;
$magenta: #FC4367;
$magenta-muted: #F0C4CC;
$magenta-light: #FFD7DF;
$merigold: #F1B331;
$merigold-light: #FFDE9D;
$merigold-muted: #E3BF76;
$orchid: #D864D4;
$orchid-light: #FAD7F9;
$orchid-muted: #DFCBDE;
$taupe-neutral: #D9D5D2;
$teal: #09ADC3;
$teal-light: #C2EAF2;
$teal-muted: #9DC6CC;
$violet: #6A6CD9;
$violet-light: #D3D3F2;
$violet-muted: #B1B2E0;

// HGTV Element State Colors


// Third-party colors
$pinterest-red: #e60019;

// original color vars
$breadcrumb-color: #4a4a4a;
$link-hover-color: #36C1D6;
$room-accent: #C2EAF2;
$separator: #f5f5f5;
