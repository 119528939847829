
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc((100% - 628px) / 2);
  position: absolute;
  left: 36px;

  &.x-size {
    width: calc((100% - 264px) / 2);
  }

  &-icon {
    border: none;
    margin: 0 !important;
    padding: 0;
    cursor: default;
  }

  &-field {
    border: none;
    outline: none;
    width: 100%;
    padding: 0;

    .input {
      font-family: Gotham SSm, Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 12px;
      color: #202429;
      border: none;
      outline: none;
      line-height: 1;
      height: 36px;

      &::placeholder {
        color: #202429;
        text-align: left;
      }

      &:focus::placeholder {
        color: #a0a0a0;
      }
    }
  }
}
