
@import '@app-styles';

.o-PhotoGalleryPromo__m-ProductsList {
  background: #fff;
  box-shadow: none;
  width: 335px;
  height: 100%;
  position: relative;
  overflow: hidden;
  opacity: 1;
  visibility: visible;

  &:before {
    filter: none;
  }

  .m-ProductsList__m-Header {
    font-family: $font-family;
    font-weight: bold;
    font-size: 0.75rem;
    text-transform: uppercase;
    min-height: 2rem;
    padding: 35px 24px 20px;
  }

  .m-ProductsList__m-Body {
    width: 100%;
    height: 488px;
    margin: 0;
    padding: 0 24px;
    overflow-y: auto;
    position: relative;

    .l-Columns {
      margin: 0;
      padding: 0;

      .m-ProductSmall {
        width: 100%;
        display: flex;
        margin: 0 0 0.5rem 0;

        .m-ProductSmall__m-Link {
          color: #202429;
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          width: 100%;
        }

        &__m-TextWrap {
          padding: 0 5px;

          .m-ProductSmall__a-Headline {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.5;
            width: 155px;
            margin: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &__m-ImageWrap {
          position: relative;

          img {
            min-width: 72px;
            height: 72px;
            object-fit: cover;

            &.on-feed {
              min-width: 105px;
              height: 105px;
            }
          }
        }

        &.left {
          .m-ProductSmall__m-Link {
            flex-direction: row;
            justify-content: space-between;
          }
        }

        &.right {
          .m-ProductSmall__m-Link {
            flex-direction: row-reverse;
            justify-content: flex-end;
          }
        }
      }

      &:last-child {
        margin-bottom: 74px;
      }
    }
  }

  .m-ProductsList__m-Footer {
    background: $white;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.085),
      0 -1px 5px 0 rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 74px;
    position: absolute;
    bottom: 0;
    padding: 12px 24px;
    z-index: 1;

    .m-ProductsList__m-Partner {
      font-family: $font-family;
      font-weight: bold;
      font-size: 0.75rem;
      color: #0fadc4;
      text-transform: uppercase;
      display: block;
      margin: 0;

      .m-Partner__a-By {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding-right: 7px;
        color: #939597;
      }
    }

    .m-ProductsList__a-LegalText {
      font-family: $font-family;
      font-size: 11px;
      color: #939597;
      margin: 0.25rem 0 0 0;
    }
  }
}
