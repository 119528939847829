
@import '@style/variables/index.scss';

.c-Feed {
  margin: 0 auto;
  width: $feed-width;
  min-height: 100vh;
  display: block;
}

nav {
  flex-direction: column;
  width: auto;

  .navbar-brand {
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05),
      0 3px 3px 1px rgba(0, 0, 0, 0.02);
    justify-content: center;
    width: 100%;

    &.navbar-buttons {
      flex-direction: column;
    }

    .buttons {
      justify-content: center;
      z-index: 1;
    }

    .buttons-facets {
      border: none;
      max-width: 1280px;
      width: 100%;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      position: relative;
    }

    .buttons-filters {
      margin: -4px 0 0 0;
      padding-bottom: 12px;
    }
  }
}

.btn-toggle-filters {
  background: #fff;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  border-width: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 3px 3px 1px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 27px;
  position: absolute;
  bottom: -26px;
  cursor: pointer;
  z-index: 2;

  &:after {
    content: '';
    border-left: 2px solid #000;
    border-top: 2px solid #000;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }
}

.filters-overlay {
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.5;
}

.card--col2 {
  flex-basis: 63%;
}

svg {
  &.icon-product {
    margin-right: 2px;
  }

  &.icon-remove {
    margin: 2px 0 1px 4px;
  }
}

.is-small {
  svg.icon-backArrow {
    margin: 0;
  }
}

.is-search {
  svg.icon-search {
    margin: 0 8px 0 -5px;
  }
}

.navbar {
  .anchor-filter {
    margin-right: 0;
  }
}

.navbar-home {
  background: #fff;
  height: 52px;
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 8px;
}

.a-ContextualTitle {
  margin-top: 1.5rem;
}

.no-scroll {
  position: absolute;

  &.photoLibraryLandingPage,
  &.photoLibraryViewerPage {
    overflow: hidden !important;
  }
}

.o-Gallery__a-Headline {
  @media only screen and (min-width: 994px) {
    font-size: 36px;
    font-weight: 300;
    width: auto;
    min-width: 994px;
    max-width: 1250px;
    margin: 30px auto;
    padding: 1rem 0 1rem 1rem;
  }
}

.o-FeedSection {
  position: relative;
  padding-top: $feed-gutter-half;
  &.isLoading {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 29;
      background: #fff;
      opacity: 0.9;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  li {
    margin: 0 3px;
    padding: 12px;
    min-width: 6px;
    height: 19px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: #000000;
    }
    &.active {
      border: 2px solid #0fadc4;
      padding: 12px 9px;
      margin-top: -2px;
    }
    &.active a,
    &.disabled a {
      cursor: pointer;
    }
    &:first-child,
    &:last-child {
      text-transform: uppercase;
    }
  }
  .pagination-wrapper {
    display: flex;
    margin-bottom: 40px;
  }
}
