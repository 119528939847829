
@import '@style/variables/index.scss';

.banner--inspiration {
  z-index: 31;
  margin-bottom: 52px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: $banner-height;
  width: auto;

  &.selected {
    margin-bottom: 125px;
    height: $banner-height-active;
    background-position: bottom;
    top: 0;
    .m-BannerImage {
      height: $banner-height-active;
      display: flex;
      align-items: center;
    }
  }

  &.collapsed {
    height: $banner-height-collapsed;
    .m-BannerImage {
      height: $banner-height-collapsed;
      background-color: #bae5ee;
    }
  }

  .m-BannerImage {
    width: 100vw;
    height: $banner-height;
    background-color: #bae5ee;
    overflow-x: hidden;
  }
}
